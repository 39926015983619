import React, { useState, useEffect } from 'react';
import { useAuth } from "../../../../authContext";
import { Link, useNavigate } from "react-router-dom";
import "./jobver0.1.css";
import JobBanner from "./job-banner.png";
import Dashnav from '../../../sidebar/dashnav';
import AiIcon from "./aicomp.png";
import FilterIcon from "./filterIcon.png";
import CopyIcon from "./copyIcon.png"
import DocUpdate from "./doc-update.png";
import Resume from "../resumeFile_list";
import AiGifIcon from "./aiIcon.gif";

const ActiveJobs = () => {
    const { isLoggedIn, user, sessionToken, fileSelect, logSessionId } = useAuth();
    const [jobList, setJobList] = useState([]);
    const [filteredJobList, setFilteredJobList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [content, setContent] = useState(null);
    const [activeJobId, setActiveJobId] = useState(null);
    const [filter, setFilter] = useState(false);
    const [activeFilter, setActiveFilter] = useState(null); // State for tracking the active filter
    const [aiResponse, setAiResponse] = useState(null); // State for tracking AI response
    const [aiPopup, setAiPopup] = useState(null); // State for AI popup
    const navigate = useNavigate();
    const [selectId, setSelectId] = useState(null); // State for 
    const [aiready, setAiready] = useState(false); // State for 
    const [selectedCity, setSelectedCity] = useState(null);
    const [searchTerm, setSearchTerm] = useState(''); // State for the search term
    const [resumePopup, setResumePopup] = useState(null);
    const [resumeResponse, setresumeResponse] = useState(null);
    useEffect(() => {
        const timer = setTimeout(() => {
            if (isLoggedIn === false) {
                navigate("/");
            }
            console.log(isLoggedIn);
        }, 2000); 
        return () => clearTimeout(timer);
    }, [isLoggedIn, navigate]);
    

    const apiEndpoint = 'https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api';

    const fetchJobs = async () => {
        try {
            if (!user?.email) {
                console.error("User email is not available.");
                return; // Exit if user email is not available
            }

            const queryObj = {
                query: `SELECT DISTINCT j.id, j.job_id, j.employer_name, j.employer_website, j.employer_company_type, j.employer_linkedin, j.job_publisher, j.job_employment_type, j.job_title, j.job_apply_link, j.job_apply_quality_score, j.job_posted_at_datetime_utc, j.job_city, j.job_state, j.job_country, j.job_highlights, j.job_is_remote, us.skill
                        FROM public.mockinterviewportal_jobs j
                        JOIN (
                            SELECT trim(unnest(string_to_array(skills, ', '))) AS skill
                            FROM public.mockinterviewportal_users
                            WHERE email = '${user.email}'
                        ) us ON j.job_title ILIKE '%' || us.skill || '%'
                        ORDER BY j.job_posted_at_datetime_utc DESC, j.job_apply_quality_score DESC`
            };
            console.log("Sending query:", queryObj); // Log the query object

            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(queryObj)
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            console.log("Received data:", data); // Log the received data
            setJobList(data);
            setSelectId(data[0]?.job_id || null); // Use optional chaining in case data is empty
            setLoading(false);
            setError(null);

            // Set default content and active job
            if (data.length > 0) {
                setContent(data[0]);
                setActiveJobId(data[0].id);
            } else {
                console.log("No results found for the given email and skills."); // Log if no results
            }
        } catch (error) {
            setError(error.message);
            setLoading(false);
            console.error("Error fetching jobs:", error); // Log the error
        }
    };

    useEffect(() => {
        if (sessionToken && user) {
            const email = user.email;
            fetchJobs(email);
        }
    }, [sessionToken, user]);

    const handleJobClick = (job) => {
        setContent(job);
        setActiveJobId(job.id);
        setSelectId(job.job_id);
        setAiResponse(null);
        setresumeResponse(null);
    };

    const filterLast24Hours = () => {
        const last24Hours = new Date(new Date().setDate(new Date().getDate() - 1));
        const filteredJobs = jobList.filter(job => new Date(job.job_posted_at_datetime_utc) > last24Hours);
        setFilteredJobList(filteredJobs);
        setFilter(true);
        setActiveFilter('last24Hours'); // Set active filter
    };

    const filterLast3Days = () => {
        const last3Days = new Date(new Date().setDate(new Date().getDate() - 3));
        const filteredJobs = jobList.filter(job => new Date(job.job_posted_at_datetime_utc) > last3Days);
        setFilteredJobList(filteredJobs);
        setFilter(true);
        setActiveFilter('last3Days'); // Set active filter
    };

    const filterLast7Days = () => {
        const last7Days = new Date(new Date().setDate(new Date().getDate() - 7));
        const filteredJobs = jobList.filter(job => new Date(job.job_posted_at_datetime_utc) > last7Days);
        setFilteredJobList(filteredJobs);
        setFilter(true);
        setActiveFilter('last7Days'); // Set active filter
    };

    const clearFilter = () => {
        setFilteredJobList([]);
        setFilter(false);
        setActiveFilter(null); // Clear active filter
        if (jobList.length > 0) {
            setContent(jobList[0]);
            setActiveJobId(jobList[0].id);
        }
    };

    /* --------------------- Apply now Script Starts --------------------------------*/

    const applyButton = async () => {
        try {
            if (!user?.email) {
                console.error("User email is not available.");
                return; // Exit if user email is not available
            }
            // Constructing the query string for PostgreSQL
            const userEmail = user.email;
            const payloadData = {
                email: userEmail,
                job_id: selectId
            };
    
            // Formatting the payload as a JSON string
            const payload = JSON.stringify(payloadData);
            
            // Creating the SQL query
            const query = `
                INSERT INTO public.mockinterviewportal_auditlog(payload, function_name, user_email, request_time)
                VALUES ('${payload}', 'clicked on Apply now', '${userEmail}', NOW());
            `;
    
            // Sending the query to the API
            const response = await fetch("https://us-east1-foursssolutions.cloudfunctions.net/connect4sphere_database_api", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ query })  // Send query in the body as JSON
            });
    
            // Handling the response
            if (!response.ok) {
                throw new Error('Failed to send data to the server');
            }
    
            const data = await response.json();
            console.log('Response:', data);
    
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    const [buttonText, setButtonText] = useState('Apply');

    useEffect(() => {
        const checkJobApplied = async () => {
            try {
                if (!user?.email) {
                    console.error("User email is not available.");
                    return; // Exit if user email is not available
                }
                const appliedResponse = await fetch('https://us-east1-foursssolutions.cloudfunctions.net/check_apply_reapply_buttons', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        user_email: user.email,
                        job_id: selectId,
                    }),
                });
    
                if (!appliedResponse.ok) {
                    throw new Error('Failed to check job application status');
                }
    
                const data = await appliedResponse.json();
                setButtonText(data.result === 'Reapply Button' ? 'Reapply' : 'Apply');
    
            } catch (error) {
                console.error('Error checking apply status:', error);
            }
        };
    
        if (selectId && user.email) {
            checkJobApplied();
        }
    }, [selectId, user]);
    


    /* --------------------- Apply now Script Ends --------------------------------*/

    const apiEndpointTwo = 'https://us-east1-foursssolutions.cloudfunctions.net/resume_upgrade_suggestions';

    const getSuggestions = async () => {
        try {
            const queryObj = { 
                "email_id": user.email, // Using the logged-in user's email
                "file_name": fileSelect, 
                "job_id": selectId,
                "session_id": logSessionId
            };

            const response = await fetch(apiEndpointTwo, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(queryObj)
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            setAiResponse(data.result);
            setAiready(true);
            setError(null);

        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };


    function openAiSuggestion() {
        setAiPopup(true);
        if (sessionToken && user && selectId) {
            getSuggestions();
        }
    }

    function closeAiSuggest() {
        setAiPopup(false);
        setAiResponse(null);
        setAiready(false);
    }

    const apiEndpointThree = 'https://us-east1-foursssolutions.cloudfunctions.net/resume_copy_suggestions';

        const getResumeUpdates = async () => {
            try {
                if (!user?.email) {
                    console.error("User email is not available.");
                    return; // Exit if user email is not available
                }
                const queryObj = { 
                    "email_id": user.email, // Using the logged-in user's email
                    "file_name": fileSelect, 
                    "job_id": selectId,
                    "session_id": logSessionId
                };

                const response = await fetch(apiEndpointThree, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(queryObj)
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const data = await response.json();
                setresumeResponse(data.result);
                    // setAiready(true);
                setError(null);

            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };


    function resumeUpdates() {
        setResumePopup(true);
        if (sessionToken && user && selectId) {
            getResumeUpdates();
        }
    }
    function closeResumeUpdates() {
        setResumePopup(false);
        setresumeResponse(null)
    }

    function copyResponse() {
        if (aiResponse) {
            navigator.clipboard.writeText(aiResponse)
                .then(() => {
                    console.log('AI response copied to clipboard');
                    alert("AI response copied to clipboard")
                })
                .catch(err => {
                    console.error('Could not copy text: ', err);
                });
        }
    }
    function copyResumeResponse(response) {
        if (response) {
            navigator.clipboard.writeText(response)
                .then(() => {
                    console.log('AI response copied to clipboard');
                    alert("AI response copied to clipboard")
                })
                .catch(err => {
                    console.error('Could not copy text: ', err);
                });
        }
    }

    const filterByCity = (city) => {
        const filteredJobs = jobList.filter(job => job.job_city === city);
        setFilteredJobList(filteredJobs);
        setFilter(true);
        setActiveFilter(city); // Set active filter to the selected city
    };
    const [cityOptions, setCityOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);

    const filterByState = (state) => {
        const filteredJobs = jobList.filter(job => job.job_state === state);
        setFilteredJobList(filteredJobs);
        setFilter(true);
        setActiveFilter(state);
    };

    useEffect(() => {
        const uniqueCities = [...new Set(jobList.map(job => job.job_city))];
        const uniqueStates = [...new Set(jobList.map(job => job.job_state))];
        setCityOptions(uniqueCities);
        setStateOptions(uniqueStates);
    }, [jobList]);

    const filterRemoteJobs = () => {
        const remoteJobs = jobList.filter(job => job.job_is_remote);
        setFilteredJobList(remoteJobs);
        setFilter(true);
        setActiveFilter('remote'); // Set active filter for remote jobs
    };


    
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value === '') {
            clearFilter();
        } else {
            const filteredJobs = jobList.filter(job =>
                job.job_title.toLowerCase().includes(e.target.value.toLowerCase()) ||
                job.employer_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
                job.job_state.toLowerCase().includes(e.target.value.toLowerCase()) ||
                job.job_city.toLowerCase().includes(e.target.value.toLowerCase()) ||
                job.job_country.toLowerCase().includes(e.target.value.toLowerCase()) ||
                job.job_employment_type.toLowerCase().includes(e.target.value.toLowerCase()) ||
                job.skill.toLowerCase().includes(e.target.value.toLowerCase())    
            );
            setFilteredJobList(filteredJobs);
            setFilter(true);
        }
    };

    const displayedJobList = filter ? filteredJobList : jobList;
    const jobCount = displayedJobList.length;


    //beautify responses
    const beautifyAiResponse = (response) => {
        if (!response) return null;
    
        // Split the response into sections by the headings
        const sections = response.split('### ').filter(Boolean);
    
        return (
            <>
                {sections.map((section, index) => {
                    const lines = section.split('\n').filter(line => line.trim() !== '');
                    const heading = lines.shift(); // Get the first line as heading
                    const content = lines.join(' ');
    
                    // Replace ** with <h3> tags
                    const formattedHeading = heading.replace(/\*\*(.*?)\*\*/g, '<h3>$1</h3>');
                    const formattedContent = content.replace(/\*\*(.*?)\*\*/g, '<h3>$1</h3>');
                    const copydata = formattedHeading + formattedContent;
    
                    // Skip rendering if heading matches "Section Order"
                    if (/Section Order/i.test(formattedHeading)) {
                        return null;
                    }
    
                    return (
                        <div key={index} className="ai-section">
                            <div className="row-flex">
                                {formattedHeading && (
                                    <h3
                                        className="ai-section-heading"
                                        dangerouslySetInnerHTML={{ __html: formattedHeading.trim() }}
                                    />
                                )}
                                <div className="copy-btn" onClick={()=> copyResumeResponse(copydata)}>
                                    <img src={CopyIcon} alt="icon" />
                                </div>
                            </div>
                            {formattedContent && (
                                <div className="ai-section-content">
                                    {formattedContent.split('- ').filter(Boolean).map((item, idx) => (
                                        <div key={idx} className="ai-content-item">
                                            <p dangerouslySetInnerHTML={{ __html: item.trim() }} />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    );
                })}
            </>
        );
    };
    const beautifyResumeResponse = (response) => {
        if (!response) return null;
    
        // Split the response into sections by the headings
        const sections = response.split('### ').filter(Boolean);
    
        return (
            <>
                {sections.map((section, index) => {
                    const lines = section.split('\n').filter(line => line.trim() !== '');
                    const heading = lines.shift(); // Get the first line as heading
                    const content = lines.join(' ');
    
                    // Replace ** with <h3> tags
                    const formattedHeading = heading.replace(/\*\*(.*?)\*\*/g, '<h3>$1</h3>');
                    const formattedContent = content.replace(/\*\*(.*?)\*\*/g, '<h3>$1</h3>');
    
                    // Skip rendering if heading matches "Section Order"
                    if (/Section Order/i.test(formattedHeading)) {
                        return null;
                    }
    
                    return (
                        <div key={index} className="ai-section">
                            {formattedHeading && (
                                <h3
                                    className="ai-section-heading"
                                    dangerouslySetInnerHTML={{ __html: formattedHeading.trim() }}
                                />
                            )}
                            {formattedContent && (
                                <div className="ai-section-content">
                                    {formattedContent.split('- ').filter(Boolean).map((item, idx) => (
                                        <div key={idx} className="ai-content-item">
                                            <p dangerouslySetInnerHTML={{ __html: item.trim() }} />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    );
                })}
            </>
        );
    };

    return (
        <>
            <div className="main-dash row-flex job">
                <Dashnav />
                <div className={`content docs col-flex`}>
                    <div className="Jobs-tophead row-flex">
                        <div className="head-col col-flex">
                            <span>Explore Job Opportunities</span>
                            <h2>Get Hired Actively</h2>
                        </div>
                        <div className="img-col col-flex">
                            <img src={JobBanner} alt="top-banner" className="banner" />
                        </div>
                    </div>
                    <div className="filter row-flex">
                        <div className="nav-head">
                            <img src={FilterIcon} alt="filter" className="icon" />
                            <span>Filter</span>
                        </div>
                        <div className={`f-nav ${activeFilter === 'last24Hours' ? 'active' : ''} ${activeFilter === 'last3Days' ? 'active' : ''} ${activeFilter === 'last7Days' ? 'active' : ''}`} >
                            <span>Filter Days</span>
                            <ul className="list col-flex">
                                <li className={`${activeFilter === 'last24Hours' ? 'active' : ''}`} onClick={filterLast24Hours}>24 Hours</li>
                                <li className={`${activeFilter === 'last3Days' ? 'active' : ''}`} onClick={filterLast3Days}>3 Days</li>
                                <li className={`${activeFilter === 'last7Days' ? 'active' : ''}`} onClick={filterLast7Days}>7 Days</li>
                            </ul>
                        </div>
                        <div className={`f-nav ${activeFilter!==null?"active":""}`} >
                            <span>Filter your City</span>
                            <ul className="list col-flex">
                                {cityOptions.map((city, index) => (
                                    city !== "NULL" && (
                                        <li className={`${activeFilter==(city)?"active": ""}`} key={index} onClick={() => filterByCity(city)}>{city}</li>
                                    )
                                ))}
                            </ul>
                        </div>
                        <div className={`f-nav ${activeFilter!==null?"active":""}`}>
                            <span>Filter State</span>
                            <ul className="list col-flex">
                                {stateOptions.map(state => (
                                    <li className={`${activeFilter==(state)?"active": ""}`} key={state} onClick={() => filterByState(state)}>{state}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="f-nav">
                        <span onClick={filterRemoteJobs} className={activeFilter === 'remote' ? 'active-remote' : ''}>Remote Jobs</span>
                        </div>
                        <div className="f-nav">
                        <input
                            type="text"
                            placeholder="Search Jobs..."
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                        </div>
                        <div className="f-nav clear" onClick={clearFilter}>
                            <span>Clear Filter</span>
                        </div>
                        {jobCount > 0 && (
                        <div className="job-count">
                            <span>{jobCount} job{jobCount > 1 ? 's' : ''} Opportunities</span>
                        </div>
                        )}
                    </div>
                    <div className="job-container row-flex">
                        <div className="selection-tab col-flex">
                            {displayedJobList.map((list) => (
                                <div
                                    className={`job-card col-flex ${activeJobId === list.id ? 'active' : ''}`}
                                    key={list.id}
                                    onClick={() => handleJobClick(list)}
                                >
                                    <div className="top row-flex">
                                        <span className="comp-name">{list.employer_name}</span>
                                        <span className="date">{new Date(list.job_posted_at_datetime_utc).toLocaleDateString('en-US')}</span>
                                    </div>
                                    <div className="middle"><h3 className="position">{list.job_title}</h3></div>
                                    <div className="bottom row-flex">
                                        <div className="location">
                                        <span className={`location ${list.job_is_remote ? "active":""}`}>{list.job_is_remote ? "Remote" : "Onsite"}</span> <span className="dot"></span><span className='text'>{list.job_city !== "NULL" ? `${list.job_city}` : ""} {list.job_state !== "NULL" ? `${list.job_state}` : ""} {list.job_country}</span>
                                        </div>
                                        {/*<span className={`posted ${list.job_apply_quality_score>0.80? "green":""} ${list.job_apply_quality_score<0.65? "red":""}`}>Score : {(100 * list.job_apply_quality_score).toFixed(0)}%</span>*/}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="description-tab">
                            {content ? (
                                <>
                                    <div className="top-row row-flex">
                                        <div className="info-lhs col-flex">
                                            <span className="comp-name">
                                                {content.employer_name}
                                            </span>
                                            <h3 className="job-position">
                                                {content.job_title}
                                            </h3>
                                            <h4 className={`location`}> <span className={` ${content.job_is_remote ? "active": ""}`}> {content.job_is_remote ? "Remote" : "OnSite"} </span><span className="dot"></span>{content.job_city !== "NULL" ? `${content.job_city}` : ""} {content.job_state !== "NULL" ? `${content.job_state}` : ""} {content.job_country}</h4>
                                            <h4 className="date">Date Posted : {new Date(content.job_posted_at_datetime_utc).toLocaleDateString('en-US')}</h4>
                                            {content.employer_linkedin !== "NULL" && <h5 className="posted">Job Posted by : <span>{content.employer_linkedin}</span></h5>}
                                            {content.employer_website !== "NULL" && <h5 className="posted">Job Website : <span>{content.employer_website}</span></h5>}
                                            {content.job_publisher !== "NULL" && <h5 className="posted">Job Publisher : <span>{content.job_publisher}</span></h5>}
                                            {/* {content.job_apply_quality_score !== "NULL" && <h5 className="posted">Job Quality Score : <span className={`${content.job_apply_quality_score>0.80?"green":""} ${content.job_apply_quality_score<0.65?"red":""}`}>{(100 * content.job_apply_quality_score).toFixed(1)}%</span></h5>} */}
                                            <h5 className="posted">Job Employment Type : <span>{content.job_employment_type}</span></h5>
                                        </div>
                                        <div className="info-rhs col-flex">
                                            <Link to={content.job_apply_link} target='_blank' onClick={applyButton}>{buttonText} Now</Link>
                                            <div className="ai-button"  onClick={resumeUpdates}> <img src={AiIcon} alt="" className="ai-icon" />Resumes Updates</div>
                                            <div
                                                className={`ai-button`} 
                                                onClick={openAiSuggestion}
                                                >
                                                    <img src={AiIcon} alt="" className="ai-icon" />AI Suggestions</div>
                                        </div>
                                    </div>
                                    <div className="bot-description col-flex">
                                        <h3 className="jdhead">Job Description</h3>
                                        <h4 className="jshead">Qualifications:</h4>
                                        <p className="jdpara">
                                            {content.job_highlights.Qualifications}.
                                        </p>
                                        <h4 className="jshead">Responsibilities:</h4>
                                        <p className="jdpara">
                                            {content.job_highlights.Responsibilities}.
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <p className='infopara'>Select a job to see the details.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {aiPopup === true && (
                <>
                    <div className="f-screenpoppup job-portal">
                        <div className="popup-cont">
                            <div className="close" onClick={closeAiSuggest}>+</div>
                            <div className="col-flex">
                                <div className="ai-head row-flex">
                                    <img src={AiIcon} alt="" className="ai-icon" />
                                    Ai Suggestions
                                    
                                </div>
                                <div className={`inner-popup ${aiResponse==null? "loading": ""}`}>
                                    {/* <h4>
                                    {aiResponse} 
                                    </h4> */}
                                    {aiResponse==null? (<div className="loader-icon"><img src={AiGifIcon} alt="image" /></div>): ""}
                                {beautifyAiResponse(aiResponse)}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {resumePopup === true && (
                <>
                    <div className="f-screenpoppup job-portal">
                        <div className="popup-cont">
                            <div className="close" onClick={closeResumeUpdates}>+</div>
                            {fileSelect != null && (
                                <div className="col-flex">
                                <div className="ai-head row-flex">
                                    <img src={DocUpdate} alt="" className="ai-icon" />
                                    Resume Updates
                                    <div className="copy-btn" onClick={() => copyResumeResponse(resumeResponse)}>
                                        <img src={CopyIcon} alt="icon" />
                                    </div>
                                </div>
                                <div className={`inner-popup ${resumeResponse==null? "loading": ""}`}>
                                {resumeResponse==null? (<div className="loader-icon"><img src={AiGifIcon} alt="image" /></div>): ""}
                                {beautifyResumeResponse(resumeResponse)}
                                </div>
                            </div>
                            )}
                            {fileSelect == null && (
                                <div className="col-flex">
                                <div className="ai-head row-flex">
                                    <img src={DocUpdate} alt="" className="ai-icon" />
                                    Choose and Reopen Resume Updates 
                                </div>
                                    <div className="col-flex" onClick={closeResumeUpdates}>
                                        <Resume/>
                                    </div>
                            </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default ActiveJobs;
